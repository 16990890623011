footer{
  .top{
    background-color: #0e4194;
    color: $color-white;
    padding: 110px 0 70px 0;
    @media(max-width: $breakpoint-992){
      padding: 30px 0;
    }
    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 0;
      @media(max-width: $breakpoint-992){
        margin-bottom: 30px;
      }
    }
    .footer-menu{
      ul{
        li{
          margin-bottom: 30px;
          @media(max-width: $breakpoint-992){
            margin-bottom: 15px;
          }
          &:last-child{
            margin-bottom: 0;
          }
          a{
            text-transform: uppercase;
          }
        }
      }
    }
    .information{
      max-width: 166px;
      @media(max-width: $breakpoint-992){
        max-width: initial;
      }
      ul{
        li{
          margin-bottom: 20px;
          position: relative;
          @media(max-width: $breakpoint-992){
            margin-bottom: 15px;
          }
          &:last-child{
            margin-bottom: 0;
          }
          img{
            position: absolute;
          }
          .text{
            padding-left: 35px;
            display: inline-block;
          }
        }
      }
    }
    .navbar-brand{
      margin: 0 0 70px 0;
      padding: 0;
      @media(max-width: $breakpoint-992){
        margin-bottom: 30px;
      }
    }
    .social-media{
      ul{
        @media(max-width: $breakpoint-992){
          margin-bottom: 0;
        }
        li{
          display: inline-block;
          margin-right: 27px;
          &:last-child{
            margin-right: 0;
          }
          a{
            background-color: $color-white;
            height: 33px;
            display: block;
            width: 33px;
            border-radius: 50%;
            position: relative;
            svg{
              color: #0e4194;
              position: absolute;
              top: 50%;
              @include transform(translatey(-50%));
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .bottom{
    color: #0e4194;
    padding: 27px 0;
    @media(max-width: $breakpoint-992){
      padding: 15px 0;
      font-size: 15px;
    }
    .left{
      display: inline-block;
      @media(max-width: $breakpoint-992){
        display: block;
        margin-bottom: 15px;
      }
      ul{
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        li{
          display: inline-block;
          margin-right: 100px;
          @media(max-width: $breakpoint-992){
            margin-right: 30px;
          }
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    .right{
      float: right;
      @media(max-width: $breakpoint-992){
        float: none;
        font-size: 15px;
      }
    }
  }
}