form{
  max-width: 905px;
  width: 100%;
  margin: 0 auto;
  input{
    display: block;
    width: 100%;
    height: 52px;
    border: solid rgba(14,65,148,.4) 2px;
    margin-bottom: 12px;
    @include placeholder(#d3d3d3);
    padding: 10px 34px;
    @media(max-width: $breakpoint-992){
      height: 40px;
      padding: 8px 15px;
      font-size: 14px;
      line-height: 16px;
    }
  }
  textarea{
    width: 100%;
    height: 140px;
    @include placeholder(#d3d3d3);
    padding: 10px 34px;
    border: solid rgba(14,65,148,.4) 2px;
    margin-bottom: 68px;
    @media(max-width: $breakpoint-992){
      height: 100px;
      padding: 8px 15px;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 30px;
    }
  }
  input[type=submit]{
    background-color: transparent;
    border: solid #0e4194 2px;
    color: #0e4194;
    width: initial;
    height: initial;
    cursor: pointer;
    @include transition(all .2s);
    padding: 10.2px 41.2px;
    font-weight: 800;
    border-radius: 20px;
    max-width: 125px;
    margin: 0 auto;
    &:hover{
      opacity: .5;
      @include transition(all .2s);
    }
  }
}