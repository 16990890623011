.news-box{
  max-width: 330px;
  margin-right: 15px;
  color: #365ba1;
  @media(max-width: $breakpoint-992){
    margin: 30px auto;
  }
  &:last-child{
    margin-right: 0;
    @media(max-width: $breakpoint-992){
      margin-right: auto;
    }
  }
  .image{
    margin-bottom: 35px;
    @media(max-width: $breakpoint-992){
      margin-bottom: 20px;
    }
    img{
      max-width: 330px;
      width: 100%;
    }
  }
  .title{
    font-size: 21px;
    line-height: 21px;
    font-weight: 800;
    margin-bottom: 6px;
    color: #0e4194;
  }
  .date{
    margin-bottom: 30px;
    @media(max-width: $breakpoint-992){
      margin-bottom: 20px;
    }
  }
  .text{
    margin-bottom: 45px;
    @media(max-width: $breakpoint-992){
      margin-bottom: 20px;
    }
  }
}
.service-box{
  text-align: center;
  max-width: 175px;
  @media(max-width: $breakpoint-992){
    max-width: 290px;
  }
  .image{
    margin-bottom: 58px;
    img{
      max-width: 57px;
    }
  }
  .title{
    color: #0e4194;
    font-weight: 800;
    margin-bottom: 77px;
  }
  .more{
    margin-bottom: 12px;
    a{
      border: solid #0e4194 2px;
      border-radius: 20px;
      padding: 9.2px 35.8px;
    }
  }
}
.info-box{
  @include display-flex();
  max-width: 350px;
  margin: 0 auto;
  color: #0e4194;
  margin-bottom: 22px;
  &:last-child{
    margin-bottom: 0;
  }
  .image{
    margin-right: 15px;
    img{
      max-width: 19px;
    }
  }
  .content{
    .title{
      font-weight: 800;
      margin-bottom: 5px;
      display: inline-block;
    }
    .text{
      display: inline-block;
      @media(max-width: $breakpoint-992){
        font-size: 15px;
        line-height: 15px;
      }
    }
  }
}
.class-box{
  max-width: 360px;
  color: #0e4194;
  @include box-shadow(0, 0, 4px, 1px, rgba(0,0,228,0.18));
  padding: 30px 45px 87px 35px;
  margin: 0 15px 18px 0;
  @media(max-width: $breakpoint-992){
    margin: 15px auto;
    padding: 15px;
  }
  &:last-child{
    margin-right: 0;
    @media(max-width: $breakpoint-992){
      margin-right: auto;
    }
  }
  .title{
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 12px;
    font-weight: 800;
    @media(max-width: $breakpoint-992){
      font-size: 20px;
      line-height: 20px;
    }
  }
  .name{
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 32px;
    @media(max-width: $breakpoint-992){
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }
  .text{
    line-height: 20px;
  }
}