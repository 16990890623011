.animated-icon1, .animated-icon3, .animated-icon4 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0;
  @include transform(rotate(0deg));
  @include transition(.5s ease-in-out);
  cursor: pointer;
}

.animated-icon1 span, .animated-icon3 span, .animated-icon4 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  @include transform(rotate(0deg));
  @include transition(.25s ease-in-out);
}

.animated-icon1 span {
  background: #073254;
}

.animated-icon3 span {
  background: #073254;
}

.animated-icon4 span {
  background: #073254;
}

.animated-icon1 span:nth-child(1) {
  top: 0;
}

.animated-icon1 span:nth-child(2) {
  top: 10px;
}

.animated-icon1 span:nth-child(3) {
  top: 20px;
}

.animated-icon1.open span:nth-child(1) {
  top: 11px;
  @include transform(rotate(135deg));
}

.animated-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.animated-icon1.open span:nth-child(3) {
  top: 11px;
  @include transform(rotate(-135deg));
}
