.page-index{
  .page-header{
    @media(max-width: $breakpoint-992){
      height: initial;
    }
    .slider-one{
      .content{
        padding: 0 30px;
        @media(max-width: $breakpoint-992){
          padding: 80px 30px;
        }
        .title{
          font-size: 62px;
          line-height: 52px;
          font-weight: 800;
          margin-bottom: 25px;
          @media(max-width: $breakpoint-1200){
            font-size: 50px;
          }
          @media(max-width: $breakpoint-992){
            font-size: 22px;
            line-height: 22px;
          }
        }
        .subtitle{
          font-size: 22px;
          line-height: 22px;
          font-weight: 800;
          @media(max-width: $breakpoint-992){
            font-size: 18px;
            line-height: 18px;
          }
        }
        .text{
          font-size: 15px;
          line-height: 18px;
          max-width: 300px;
          margin-bottom: 45px;
          @media(max-width: $breakpoint-992){
            max-width: initial;
          }
        }
        .popup-button{
          a{
            border: solid $color-white 2px;
            padding: 9.4px 21.3px;
            border-radius: 22px;
            margin-right: 26px;
            @media(max-width: $breakpoint-992){
              padding: 8px 15px;
              margin-right: 15px;
              font-size: 14px;
            }
            &:last-child{
              margin-right: 0;
            }
          }
        }
      }
      .slick-dots{
        left: 0;
        top: 50%;
        @include transform(translatey(-50%));
        margin-bottom: 0;
        width: 30px;
        bottom: initial;
        li{
          margin: 0;
          &.slick-active{
            button{
              &:before{
                color: $color-white;
                opacity: 1;
              }
            }
          }
          button{
            &:before{
              font-size: 30px;
              color: #ffcd00;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .section-information{
    padding: 45px 0;
    .image{
      max-width: 191px;
      margin: 0 auto 28px auto;
      img{
        max-width: 100%;
        @include box-shadow(5px, 5px, 8px, 0, rgba(200,205,207,1));
        border-radius: 95px;
      }
    }
    .title{
      font-size: 41px;
      line-height: 41px;
      font-weight: 800;
      margin-bottom: 25px;
      color: #0e4194;
      text-align: center;
      @media(max-width: $breakpoint-992){
        font-size: 22px;
        line-height: 22px;
      }
    }
    .text{
      margin-bottom: 25px;
      line-height: 20px;
      color: #494949;
      @media(max-width: $breakpoint-992){
        font-size: 14px;
      }
    }
    .popup-button{
      margin-bottom: 12px;
      text-align: center;
      a{
        font-weight: 800;
        border: solid #0e4194 2px;
        padding: 8.5px 19.3px;
        border-radius: 20px;
        color: #0e4194;
      }
    }
  }
}