.page-single{
  color: #0e4194;
  .title{
    font-size: 25px;
    line-height: 25px;
    font-weight: 800;
    margin-bottom: 3px;
    @media(max-width: $breakpoint-992){
      font-size: 20px;
      line-height: 20px;
    }
  }
  .date{
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 3px;
    @media(max-width: $breakpoint-992){
      font-size: 20px;
      line-height: 20px;
    }
  }
  .image{
    width: 100%;
    max-width: 568px;
    display: inline-block;
    margin: 0 0 25px 25px;
    float: right;
    @media(max-width: $breakpoint-992){
      float: none;
      margin: 15px 0;
    }
    img{
      max-width: 100%;
    }
  }
  .content{
    margin-bottom: 100px;
    @media(max-width: $breakpoint-992){
      margin-bottom: 50px;
    }
  }
}