input, textarea {
  outline: none !important;
}

* {
  outline: none !important;
}

html {
  height: 100%;
}

body {
  font-family: $font-base;
  font-weight: 400;
  font-size: 16.6px;
  line-height: 18px;
}

a {
  color: inherit;
  text-decoration: none !important;
  @include transition(all .2s);
  &:hover{
    opacity: .5;
    @include transition(all .2s);
  }
}

a:hover, a:focus {
  color: inherit;
}

textarea {
  resize: none;
}

.w-100 {
  width: 100%;
}

.text-center-992 {
  @media (max-width: $breakpoint-992) {
    text-align: center;
  }
}

.hide-768 {
  @media (max-width: $breakpoint-768) {
    display: none;
  }
}

.show-768 {
  display: none;

  @media (max-width: $breakpoint-768) {
    display: inline-block;
  }
}

.row-eq-height {
  @media (min-width: 993px) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display:         flex;
  }
}

.text-lines {
  color: #000;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;

  &:before,
  &:after {
    content: "";
    background-color: #000;
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.col-centered {
  float: none;
  margin: 0 auto;
}