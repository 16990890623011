@import "modules/fonts";
@import "modules/variables";
@import "modules/mixins";
@import "modules/helpers";
@import "modules/spacing";

@import "partials/form";
@import "partials/btn";
@import "partials/header";
@import "partials/footer";
@import "partials/page";
@import "partials/icons";
@import "partials/navigation";
@import "partials/modals";
@import "partials/slick";
@import "partials/breadcrumb";

@import "page/page";
@import "page/index";
@import "page/about";
@import "page/services";
@import "page/media";
@import "page/contact";
@import "page/requirement";
@import "page/single";
@import "page/classes";

@import "elements/article-box";