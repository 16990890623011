.page-requirement{
  form{
    max-width: initial;
    padding: 105px 0 100px 0;
    @media(max-width: $breakpoint-992){
      padding: 30px 0;
    }
    input{
      max-width: 549px;
      display: inline-block;
      margin-right: 4px;
      @include placeholder(rgba(14, 65, 148, 0.4));
      color: rgba(14, 65, 148, 0.4);
      @media(max-width: $breakpoint-1200){
        max-width: 459px;
      }
      @media(max-width: $breakpoint-992){
        max-width: initial;
      }
    }
    select{
      max-width: 549px;
      width: 100%;
      height: 52px;
      border: solid rgba(14,65,148,.4) 2px;
      @include placeholder(rgba(14, 65, 148, 0.4));
      color: rgba(14, 65, 148, 0.4);
      padding: 10px 34px;
      margin-bottom: 12px;
      @media(max-width: $breakpoint-1200){
        max-width: 459px;
      }
      @media(max-width: $breakpoint-992){
        max-width: initial;
      }
      option{
        color: rgba(14, 65, 148, 0.4);
      }
    }
    input[type=submit]{
      margin: 80px auto 0 auto;
      display: block;
      color: #0e4194;
      @media(max-width: $breakpoint-992){
        margin-top: 30px;
      }
    }
  }
}