header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $color-white;
  &:after{
    content: '';
    @include box-shadow(0, 5px, 6px, -3px, rgba(0,0,0,0.75));
    position: absolute;
    width: 100%;
    height: 7px;
    bottom: 0;
  }
  .navbar{
    padding: 15px 0 18px 0;
    @media(max-width: $breakpoint-992){
      padding: 15px 0;
    }
    .navbar-brand{
      margin-right: 40px;
      @media(max-width: $breakpoint-1200){
        margin-right: 20px;
      }
      @media(max-width: $breakpoint-992){
        margin: 0 15px 15px 0;
      }
      img{
        max-width: 185px;
        @media(max-width: $breakpoint-1200){
          max-width: 130px;
        }
        @media(max-width: $breakpoint-992){
          max-width: 120px;
        }
      }
    }
    #navbarSupportedContent20{
      ul{
        li{
          margin-right: 18px;
          @media(max-width: $breakpoint-1200){
            margin-right: 14px;
          }
          @media(max-width: $breakpoint-992){
            margin-right: initial;
            text-align: center;
            margin-bottom: 15px;
          }
          &.active{
            a{
              border-bottom: solid #073254 1px;
            }
          }
          a{
            color: #073254;
            text-transform: uppercase;
            padding: 0;
            font-size: 13px;
            @media(max-width: $breakpoint-1200){
              font-size: 12px;
            }
            @media(max-width: $breakpoint-992){
              display: inline-block;
              font-size: 16px;
            }
          }
        }
      }
      .navbar-button{
        li{
          margin-right: 0;
          a{
            font-size: 12px;
            background-color: #ffce00;
            padding: 11.5px 12.7px;
            border-radius: 20px;
            color: #0e4194;
            text-transform: initial;
            @media(max-width: $breakpoint-1200){
              font-size: 10px;
            }
            svg{
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}