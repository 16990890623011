.page-about{
  .about{
    color: #0e4194;
    padding-top: 73px;
    @media(max-width: $breakpoint-992){
      padding-top: 30px;
    }
    .title{
      font-size: 41px;
      line-height: 40px;
      font-weight: 800;
      margin-bottom: 30px;
      @media(max-width: $breakpoint-992){
        font-size: 30px;
        line-height: 30px;
      }
    }
    .text{
      color: #a0a0a0;
      line-height: 20px;
      margin-bottom: 20px;
    }
    .popup-button{
      font-size: 14.5px;
      line-height: 15px;
      text-align: right;
      margin-bottom: 50px;
    }
    .background-about{
      height: 500px;
      @media(max-width: $breakpoint-992){
        height: 300px;
      }
    }
  }
  .about-content{
    padding: 174px 0 170px 0;
    @include background-size(initial);
    background-position: 21% center;
    @media(max-width: $breakpoint-992){
      padding: 30px 0;
    }
    .content{
      > div{
        max-width: 500px;
        margin-right: 132px;
        @media(max-width: $breakpoint-992){
          max-width: initial;
          margin-right: 0;
          margin-bottom: 15px;
        }
        &:last-child{
          margin-right: 0;
        }
        .title{
          color: #0e4194;
          font-size: 41px;
          line-height: 40px;
          font-weight: 800;
          margin-bottom: 20px;
          @media(max-width:$breakpoint-992){
            font-size: 25px;
            line-height: 25px;
          }
        }
        .text{
          color: #a0a0a0;
          line-height: 18px;
        }
      }
    }
  }
}