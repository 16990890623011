.breadcrumb{
  background-color: $color-white;
  text-transform: uppercase;
  font-weight: 800;
  margin: 55px 0 35px 0;
  padding: 0;
  font-size: 12px;
  @media(max-width: $breakpoint-992){
    margin: 30px 0;
  }
  ul{
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    li{
      display: inline-block;
      &:last-child{
        &:after{
          content: '';
          margin: 0;
        }
      }
      &:after{
        content: '>';
        margin: 0 5px;
      }
    }
  }
}