@font-face {
  font-family: 'SFCompactDisplay';
  src: url('fonts/SFCompactDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('fonts/SFCompactDisplay-Bold.woff') format('woff'),
  url('fonts/SFCompactDisplay-Bold.ttf')  format('truetype'),
  url('fonts/SFCompactDisplay-Bold.svg#SFCompactDisplay-Bold') format('svg');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'SFCompactDisplay';
  src: url('fonts/SFCompactDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('fonts/SFCompactDisplay-Regular.woff') format('woff'),
  url('fonts/SFCompactDisplay-Regular.ttf')  format('truetype'),
  url('fonts/SFCompactDisplay-Regular.svg#SFCompactDisplay-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SFCompactDisplay';
  src: url('fonts/SFCompactDisplay-Light.eot?#iefix') format('embedded-opentype'),
  url('fonts/SFCompactDisplay-Light.woff') format('woff'),
  url('fonts/SFCompactDisplay-Light.ttf')  format('truetype'),
  url('fonts/SFCompactDisplay-Light.svg#SFCompactDisplay-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}