.page{
  padding-top: 124.55px;
  @media(max-width: $breakpoint-1200){
    padding-top: 100.3px;
  }
  @media(max-width: $breakpoint-992){
    padding-top: 107.9px;
  }
  &.page-information{
    color: #0e4194;
    .title{
      font-size: 25px;
      line-height: 25px;
      font-weight: 800;
      margin: 75px 0 37px 0;
    }
    .content{
      margin-bottom: 110px;
    }
  }
  .page-header{
    height: 535px;
    @media(max-width: $breakpoint-992){
      height: 300px;
    }
  }
  .section-title{
    font-size: 41px;
    line-height: 41px;
    color: #0e4194;
    font-weight: 800;
    margin: 22px 0;
    @media(max-width: $breakpoint-992){
      font-size: 30px;
      line-height: 30px;
      text-align: center;
    }
  }
  .more{
    a{
      font-size: 15px;
      line-height: 15px;
      color: #0e4194;
      font-weight: 800;
    }
  }
  .news{
    padding: 20px 0 75px 0;
    @media(max-width: $breakpoint-992){
      padding: 30px 0;
    }
    .see-more{
      text-align: center;
      margin: 82px 0 12px 0;
      a{
        color: #0e4194;
        font-weight: 800;
        border: solid #0e4194 2px;
        padding: 9.4px 32.8px;
        border-radius: 20px;
      }
    }
  }
  .popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100%;
    height: 100%;
    .background{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0,0,0,.8);
    }
    .popup-close{
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      a{
        color: $color-white;
      }
    }
    .popup-content{
      position: absolute;
      top: 50%;
      @include transform(translatey(-50%));
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: $color-white;
      max-width: 1110px;
      width: 100%;
      padding: 30px 15px;
      @media(max-width: $breakpoint-992){
        padding: 15px;
      }
      &.popup-information{
        color: #0e4194;
        .popup-title{
          font-size: 35px;
          line-height: 35px;
          font-weight: 800;
          margin-bottom: 5px;
          @media(max-width: $breakpoint-992){
            font-size: 20px;
            line-height: 20px;
          }
        }
        .popup-subtitle{
          color: #ffce00;
          font-size: 25px;
          line-height: 25px;
          font-weight: 800;
          @media(max-width: $breakpoint-992){
            font-size: 18px;
            line-height: 18px;
          }
        }
        .popup-text{
          color: #4b4b4b;
          font-weight: 300;
          line-height: 18px;
          margin-top: 15px;
          @media(max-width: $breakpoint-992){
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .counter{
    display: none;
  }
}