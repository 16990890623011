.page-services{
  .services-boxes{
    .box{
      width: 50%;
      @media(max-width: $breakpoint-992){
        width: 100%;
        background-size: 35%;
      }
    }
    .content{
      width: 50%;
      @media(max-width: $breakpoint-992){
        width: 100%;
        background-size: 35%;
      }
    }
    .inner{
      max-width: 380px;
      color: #a0a0a0;
      margin-top: 59px;
      @media(max-width: $breakpoint-992){
        margin-top: 0;
      }
    }
    > div{
      padding: 157px 0 140px 0;
      @include background-size(auto);
      @media(max-width: $breakpoint-992){
        padding: 100px 15px;
      }
    }
    .background-lb{
      background-position: left bottom;
    }
    .background-rb{
      background-position: right bottom;
    }
    .background-rt{
      background-position: right top;
    }
    .background-lt{
      background-position: left top;
    }
    .background-lc{
      background-position: left center;
    }
    .left{
      margin-left: auto;
      margin-right: 217px;
      @media(max-width: $breakpoint-1200){
        margin-right: auto;
      }
    }
    .right{
      margin-right: auto;
      margin-left: 123px;
      @media(max-width: $breakpoint-1200){
        margin-left: auto;
      }
    }
    .colored-background{
      background-color: rgba(14,65,148,.1);
    }
    &.swap-mobile{
      @media(max-width: $breakpoint-992){
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -moz-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        > div{
          &:first-child{
            -webkit-box-ordinal-group: 2;
            -moz-box-ordinal-group: 2;
            -ms-flex-order: 2;
            -webkit-order: 2;
            order: 2;
          }
          &:last-child{
            -webkit-box-ordinal-group: 1;
            -moz-box-ordinal-group: 1;
            -ms-flex-order: 1;
            -webkit-order: 1;
            order: 1;
          }
        }
      }
    }
  }
}