.slider-one{
  margin-bottom: 0;
  height: 100%;
  .slick-list{
    height: 100%;
    .slick-track{
      height: 100%;
      .slick-slide{
        position: relative;
        .content{
          color: $color-white;
          position: absolute;
          top: 50%;
          @include transform(translatey(-50%));
          left: 0;
          right: 0;
          @media(max-width: $breakpoint-992){
            position: initial;
            @include transform(initial);
          }
        }
      }
    }
  }
}
.slider-three{
  margin-bottom: 48px;
  position: relative;
  .slick-arrow{
    position: absolute;
    color: $color-white;
    top: 50%;
    @include transform(translatey(-50%));
    z-index: 1;
    cursor: pointer;
    background-color: #0e4194;
    font-size: 22px;
    height: 72px;
    width: 33px;
    border: none;
    @include transition(all .2s);
    &:hover{
      opacity: .5;
    @include transition(all .2s);
    }
    &.next{
      right: 0;
      padding: 25px 3px 25px 14px;
      border-radius: 50px 0 0 50px;
    }
    &.prev{
      left: 0;
      padding: 25px 14px 25px 3px;
      border-radius: 0 50px 50px 0;
    }
  }
  .slider-for{
    height: 485px;
    margin: 0;
    @media(max-width: $breakpoint-992){
      height: 250px;
    }
    .slick-list{
      height: 100%;
      .slick-track{
        height: 100%;
      }
    }
  }
  .slider-nav{
    height: 204px;
    width: 680px;
    margin: 0 auto;
    position: relative;
    top: -150px;
    margin-bottom: -150px;
    @media(max-width: $breakpoint-992){
      position: initial;
      margin-bottom: 0;
      margin-top: 15px;
      width: 100%;
      height: 120px;
    }
    .slick-list{
      height: 100%;
      .slick-track{
        height: 100%;
        .slick-slide{
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .play{
    position: absolute;
    top: 50%;
    @include transform(translatey(-50%));
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgba(250,250,250,.5);
    border-radius: 50%;
    img{
      position: absolute;
      top: 50%;
      @include transform(translatey(-50%));
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .slick-for-video{
    .slick-slide{
      position: relative;
      .play{
        height: 125px;
        width: 125px;
        img{
          max-width: 45px;
        }
      }
      .background-attachment{
        position: absolute;
        height: 100%;
        width: 100%;
      }
    }
  }
  .slick-nav-video{
    .slick-slide{
      position: relative;
      .play{
        height: 49px;
        width: 49px;
        img{
          max-width: 16px;
        }
      }
    }
  }
}