.background-attachment{
  @include background-cover();
  background-position: center;
  background-repeat: no-repeat;
}
.scroll-up{
  position: fixed;
  right: 0;
  bottom: 30px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  @include transition(opacity .3s linear);
  &.show{
    opacity: 1;
    visibility: visible;
    @include transition(opacity .3s linear);
  }
  button{
    cursor: pointer;
    background-color: $color-white;
    color: #0e4194;
    border: none;
    font-size: 20px;
    padding: 5px 8px;
    @include transition(all .2s);
    @include box-shadow(0, 0, 3px, 2px, #cccccc);
    &:hover{
      opacity: .6;
      @include transition(all .2s);
    }
  }
}
/* My Safari 7.1+ CSS Hack */
_::-webkit-full-page-media, _:future, :root .page .page-header .slider-one .slick-dots li button::before {
  font-size: 15px;
}