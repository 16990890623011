.page-contact{
  .form{
    background-position: right bottom;
    @include background-size(auto);
    padding: 65px 0 95px 0;
    @media(max-width: $breakpoint-992){
      padding: 30px 0;
    }
    .title{
      color: rgba(14,65,148,.4);
      font-weight: 800;
      max-width: 905px;
      width: 100%;
      margin: 0 auto 25px auto;
      @media(max-width: $breakpoint-992){
        font-size: 14px;
      }
    }
  }
  .location{
    .info{
      width: 50%;
      background-color: rgba(14,65,148,.1);
      padding: 58px 0 70px 0;
      @media(max-width: $breakpoint-992){
        width: 100%;
        padding: 30px 15px;
      }
      .border{
        max-width: 350px;
        margin: 45px auto;
        border: solid #0e4194 1px !important;
        @media(max-width: $breakpoint-992){
          margin: 15px auto;
        }
      }
    }
    .map{
      width: 50%;
      position: relative;
      text-align: center;
      @media(max-width: $breakpoint-992){
        width: 100%;
        height: 250px;
      }
      a{
        position: absolute;
        top: 50%;
        @include transform(translatey(-50%));
        color: $color-white;
        border: solid $color-white 2px;
        font-weight: 800;
        border-radius: 20px;
        padding: 9.4px 33.6px;
        margin: 0 auto;
        left: 0;
        right: 0;
        max-width: 169px;
        @media (max-width: $breakpoint-992) {
          padding: 9px 15px;
        }
      }
    }
  }
}