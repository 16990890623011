.page-media{
  .news{
    padding: 0;
    margin: 20px 0 60px 0;
    @media(max-width: $breakpoint-992){
      margin: 30px 0;
    }
  }
  .news-boxes{
    margin-bottom: 50px;
  }
  .location{
    iframe{
      @media(max-width: $breakpoint-992){
        height: 400px;
      }
    }
  }
}